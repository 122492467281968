import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from '../Layout/Layout';
import { Home } from '../Page/Home/Home';
import { About } from '../Page/About/About';
import { Contact } from '../Page/Contact/Contact';
import { Resources } from '../Page/Resources/Resources';
import { Solutions } from '../Page/Solutions/Solutions';
import { Dynamics } from '../Page/Dynamics/Dynamics';
import { PowerApps } from '../Page/PowerApps/PowerApps';
import { TeamViewer } from '../Page/TeamViewer/TeamViewer';
import { NotFound } from '../Page/NotFound/NotFound';
import { Aviso } from '../Page/Aviso/Aviso'; 
import { Terminos } from '../Page/Terminos/Terminos'; 
import { Preguntas } from '../Page/Preguntas/Preguntas'; 
import FloatingButton from "../Components/BotonFlotante/FloatingButton";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Assets/Style/global.scss'
import 'bootstrap-icons/font/bootstrap-icons.css';

const App = () => {
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/resources' element={<Resources />} />
                    <Route path='/solutions' element={<Solutions />} />
                    <Route path='/dynamics' element={<Dynamics />} />
                    <Route path='/powerapps' element={<PowerApps />} />
                    <Route path='/teamviewer' element={<TeamViewer />} />
                    <Route path='/aviso' element={<Aviso />} />
                    <Route path='/terminos' element={<Terminos />} />  
                    <Route path='/preguntas' element={<Preguntas />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>

                {/* Botón flotante para el chatbot */}
                <FloatingButton />
            </Layout>
        </BrowserRouter>
    );
};

export { App };
