import React, { Component } from 'react';
import "./About.css";
import nosotros from "../../Assets/Images/About/Imagen 1.png";
import nosotrosMobile from "../../Assets/Images/About/Movil/Imagen 1.png";
import automatización from "../../Assets/Images/About/Imagen 2.png";
import Mision from "../../Assets/Images/About/Mision.png";
import Vision from "../../Assets/Images/About/Vision.png";
import fondoMision from "../../Assets/Images/About/Fondo-mision.png";
import Valores from "../../Assets/Images/About/Valores.png";
import Servicio from "../../Assets/Images/About/Servicio al cliente.png";
import Legal from "../../Assets/Images/About/Legal.png";
import mkt from "../../Assets/Images/About/Ventas y mkt.png";
import operaciones from "../../Assets/Images/About/Operaciones.png";
import Procesos from "../../Assets/Images/About/Procesos.png";
import Administracion from "../../Assets/Images/About/Administracion.png";
import Base from "../../Assets/Images/About/Casos linea.png";
import caso1 from "../../Assets/Images/About/Casos de exito/Grupo 1543.png";
import caso2 from "../../Assets/Images/About/Casos de exito/Grupo 1544.png";
import caso3 from "../../Assets/Images/About/Casos de exito/Grupo 1545.png";
import Card1 from "../../Assets/Images/About/Movil/marketing.png"
import Card2 from "../../Assets/Images/About/Movil/servicio.png"
import Card3 from "../../Assets/Images/About/Movil/operaciones.png"
import Card4 from "../../Assets/Images/About/Movil/procesos.png"
import Card5 from "../../Assets/Images/About/Movil/administracion.png"
import Card6 from "../../Assets/Images/About/Movil/legal.png"
import { Title } from "../../Components/Title/Title"; 
import { Button } from "../../Components/Button/Button";
import { Card } from "../../Components/Card/Card";
import { Subtitle } from "../../Components/Subtitle/Subtitle";
import { useRef } from 'react';
import { CarouselSwiper } from "../../Components/CarouselSwiper/CarouselSwiper";

const About = () => {
  

  const myRef = useRef(null);

    const scrollToElement = () => {
      if (myRef.current) {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }

    
   return (
    <div className="About">
      <div className="row about-intro">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 about-intro__content-img">
          <img src={nosotros} alt="" className="about-intro--img" /> 
          <img src={nosotrosMobile} alt="" className="about-intro--img--mobile" /> 
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 about-intro-info">
          <div className="row">
            <div className="col-12 mt-5 d-movile about-intro--title">
              <Title>
                <span className="color-movile">Nosotros</span>
              </Title>
            </div>
            <div className="col-12 mt-5 d-pc">
              <Title>Nosotros</Title>
            </div>
            <div className="col-12 mt-5 about-intro__p">
              <p className="about-intro__p--description">
                Redinet se fundó hace más de 17 años con el propósito de brindar
                un excelente servicio a nuestros clientes y acompañándolos paso
                a paso en su transformación digital.
              </p>
            </div>
            <div className="col-12 mt-5 about-intro__button">
                <Button classButton="button__prymary" onClick={scrollToElement}>
                  CONOCE A NUESTRO EQUIPO
                </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="row about-automatizada top_10">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 about-info">
          <div className="row">
            <div className="col-12 mt-5 d-movile about-title">
              <Title>
                <span className="color-movile">
                  Somos una empresa 100% automatizada
                </span>
              </Title>
            </div>
            <div className="col-12 mt-5 d-pc">
              <Title>
                Somos una empresa 100% automatizada
                <br />
              </Title>
            </div>
            <div className="col-12 mt-5 about__p">
              <p className="about-intro__p--description">
                Soportamos y aseguramos la continuidad de negocio con
                estrategias de administración al cambio como la universidad
                virtual.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 about__content-img">
          <img src={automatización} alt="" className="about-info-img" />
        </div>
      </div>

      <div className="cards-mision-vision top_10 ">
        <div className="secction-cards">
        <Card title="Misión" image={Mision}>
          Satisfacer las necesidades de nuestros clientes con los más altos
          estándares de calidad, contribuyendo a que alcancen sus objetivos a
          través de un amplio abanico de soluciones.
        </Card>
        <Card title="Visión" classColorTitle="vision" image={Vision}>
          Ser una compañía multinacional y reconocida a nivel Latinoamérica
          especializada en brindar un amplio abanico de soluciones
          manteniéndonos siempre a la vanguardia
        </Card>
        <Card title="Valores" image={Valores}>
          Redinet se fundó hace más de 17 años con el propósito de brindar un
          excelente servicio a nuestros clientes y acompañándolos paso a paso en
          su transformación digital.
        </Card>
        </div>
        <div className="fondo-mision-vision">
          <img className="img-fondo-vision" src={fondoMision}/>
        </div>
      </div>

      <div className="about-trade row especialistas">
        <div className="about-trade__img-back"></div>
        <div className="col-12">
          <Subtitle classSubTitle="subtitle__color--secundary-a text-center">
            DEJA A TU EMPRESA EN MANOS DE LOS EXPERTOS
          </Subtitle>
          <div className="col-12 mt-5 about-intro__button">
            <a href="tel:+52 55 8977 6502">
              <Button classButton="button__prymary">
                LLAMA A UN ESPECIALISTA
              </Button>
            </a>
          </div>
          <Title>Nuestro equipo</Title>

        </div>
      </div>
      
      <div ref={myRef} className="Nuestro Equipo top_10 ">
        <Card>
        <img className="img-nuestro-equipo" src={Servicio}/>
        <Title><span class="color-subitlte">Servicio</span> al cliente</Title>
        <span>Teams PBX, Customer Services, Ofimatica, Virtual Agents, Power Pages.</span>  
        </Card>
        <Card>
        <img className="img-nuestro-equipo" src={mkt}/>
        <Title><span class="color-subitlte">Ventas</span> y marketing</Title>
        <span>Sales insights, Dynamics, Analitica predictiva..</span>  
        </Card>
        <Card>
        <img className="img-nuestro-equipo" src={operaciones}/>
        <Title><span class="color-subitlte">Operaciones</span></Title>
        <span>Azure, AWS, Dynamics ERP.</span>  
        </Card>
        <Card>
        <img className="img-nuestro-equipo" src={Procesos}/>
        <Title><span class="color-subitlte">Procesos</span></Title>
        <span>Azure Cognitive Services, Power Automate, LogicApps.</span>  
        </Card>
        <Card>
        <img className="img-nuestro-equipo" src={Administracion}/>
        <Title><span class="color-subitlte">Administración</span> y finanzas</Title>
        <span>Financial, Business Central, Power BI, Big Data, Analítica predictiva.</span>  
        </Card>
        <Card>
        <img className="img-nuestro-equipo" src={Legal}/>
        <Title><span class="color-subitlte">Legal</span></Title>
        <span>Sharepoint, Power automate, RDS, EMS, Power apps.</span>  
        </Card>
      </div>      
      <div className='row resources-caroucel-mobile_equipos'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5 equipos'>
          <CarouselSwiper ArrayImages={[Card1, Card2, Card3, Card4, Card5 , Card6 ]} />
        </div>
      </div>
      <div className="tittle-casos-de-exito top_10 ">
      <Title>Casos de éxito <span class="color-subitlte">globales</span></Title>
      </div>
      <div className="casos_de_exito top_10 ">
        <div className="cards-casos--de--exito">
          <Card>
            Gracias a la adopción de Business Central, ahora todo el equipo de
            FASI puede consultar, de manera inmediata, gastos y movimientos de
            cada casa de acogida, facilitando así una gestión optimizada de los
            recursos dentro de un entorno seguro."
            <span className="name-equipo-text1">Araceli</span>      
            <span className="equipo-left-text1">Reales Responsable de Administración en FASI</span>
          </Card>
          <Card classColorTitle="work">
            "El trabajo diario es más sencillo y rápido que antes puesto que
            tenemos toda la información comercial registrada en un único
            soporte, ordenada y accesible desde cualquier sitio y momento.
            Dynamics 365 Sales y Microsoft 365 lo han hecho posible"
            <span className="name-equipo-text2">Erika Robredo</span>      
            <span className="equipo-left-text2">Business Development Manager de Euro Seating International</span>
          </Card>
          <Card>
            Business Central en sí mismo es una solución mucho más potente que
            Navision, con una navegabilidad más ágil. Pero, además, el entorno
            actual de Dynamics 365 lleva consigo una serie de herramientas y
            aplicaciones"
            <span className="name-equipo-text">Stefan Schuster</span>      
            <span className="equipo-left-2">CEO en TECNALIA</span>
          </Card>
        </div>
       

        <img className="cards_casos--de--exito" src={Base} />
      </div>

      <div className='row resources-caroucel-mobile'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5'>
          <CarouselSwiper ArrayImages={[caso1, caso2, caso3]} />
        </div>
      </div>

    </div>
  );
};

export { About };
