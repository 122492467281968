import React, { useState } from "react";
import Chatbot from "../../Page/ChatBot/Chatbot"; // Ajusta la ruta si es necesario
import "./FloatingButton.scss"; // Importa el diseño

const FloatingButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="floating-button-container">
      <button className="floating-button" onClick={() => setIsOpen(!isOpen)}>
        
      </button>
      {isOpen && (
        <div className="chatbot-container">
          <Chatbot />
        </div>
      )}
    </div>
  );
};

export default FloatingButton;


