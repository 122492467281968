import React from "react";
import "./Aviso.scss";

const Aviso = () => {
  return (
    <div className="aviso-privacidad">
      <h1>Aviso de Privacidad</h1>
      <p>Fecha de la actualización: 08 de enero del 2025.</p>
      <p>
        En cumplimiento con la Ley Federal de Protección de Datos Personales en
        Posesión de los Particulares, ponemos a su disposición el presente
        aviso de privacidad, y al respecto le informamos lo siguiente:
      </p>
      <h2>1. Responsable de la protección de sus datos personales</h2>
      <p>
        CONNECTIVE IT SOLUTIONS, S. DE R.L. de C.V. (en lo sucesivo denominado
        “CONNECTIVE IT SOLUTIONS”), con domicilio para oír y recibir
        notificaciones en Calle Zamora 165 B4, Col. Condesa, Alcaldía
        Cuauhtémoc, Ciudad de México, C.P. 06140, Ciudad de México, México, es
        responsable del tratamiento y protección de sus datos personales.
      </p>
      <h2>2. Información sobre nuestro oficial de privacidad y cómo contactarlo</h2>
      <p>
        Nuestro oficial de privacidad es la persona al interior de CONNECTIVE
        IT SOLUTIONS que, en cumplimiento con el artículo 30 de la Ley Federal
        de Protección de Datos Personales en Posesión de los Particulares, ha
        sido formalmente designado para:
      </p>
      <ul>
        <li>Atender las solicitudes de los titulares para el ejercicio de sus derechos.</li>
        <li>
          Fomentar y velar por la protección de los datos personales que
          tenemos en nuestra posesión.
        </li>
      </ul>
      <p>
        Para todo lo relacionado con el tratamiento y protección de sus datos
        personales, usted podrá contactarse con nuestro oficial de privacidad
        enviando un correo electrónico a la siguiente dirección:{" "}
        <a href="mailto:atencionaclientes@connectiveit.com.mx">
          atencionaclientes@connectiveit.com.mx
        </a>
        .
      </p>
      <h2>3. Datos personales que recabamos de usted</h2>
      <p>
        Para realizar las finalidades señaladas en el numeral 4 (cuatro) del
        presente aviso de privacidad, recabaremos las siguientes categorías de
        datos personales:
      </p>
      <ul>
        <li>(i) Identificación</li>
        <li>(ii) Contacto</li>
        <li>(iii) Laborales</li>
        <li>(iv) Financieros</li>
      </ul>
      <h3>3.1. Datos personales sensibles</h3>
      <p>No recabamos datos personales sensibles de nuestros proveedores.</p>
      <h2>4. Finalidades del tratamiento de los datos personales recabados</h2>
      <p>Sus datos personales son recabados para las siguientes finalidades primarias:</p>
      <ul>
        <li>Identificarlo y contactarlo.</li>
        <li>Evaluar la posibilidad de contratarlo como proveedor o prestador de servicios profesionales.</li>
        <li>Validar sus datos fiscales y bancarios.</li>
        <li>
          En su caso, darlo de alta como proveedor o prestador de servicios
          profesionales en nuestros sistemas.
        </li>
        <li>Prestación de sus servicios.</li>
        <li>Efectuarle los pagos correspondientes.</li>
        <li>Videovigilancia, por razones de seguridad.</li>
      </ul>
      <p>
        Sus datos personales <strong>NO</strong> serán utilizados para finalidades secundarias.
      </p>
      <h2>5. Transferencia de sus datos personales</h2>
      <p>
        Para el cumplimiento de las finalidades primarias señaladas en el
        numeral 4 (cuatro), sus datos personales podrán ser transferidos a las
        siguientes personas físicas o morales:
      </p>
      <ul>
        <li>
          <strong>Autoridades:</strong> Para cumplir con disposiciones legales
          correspondientes.
        </li>
        <li>
          <strong>Sociedades de Grupo IWS:</strong> Que operen bajo nuestros
          mismos procesos y políticas internas. Estas sociedades establecerán
          medidas de seguridad administrativas, técnicas y físicas que
          protejan sus datos personales contra daño, pérdida, alteración,
          destrucción o uso indebido.
        </li>
      </ul>
      <p>CONNECTIVE IT SOLUTIONS <strong>NO</strong> realiza transferencias que requieran su consentimiento.</p>
      <h2>6. Derechos ARCO</h2>
      <p>
        Usted tiene derecho a conocer qué datos personales tenemos de usted
        (Acceso), solicitar la corrección (Rectificación), eliminar sus datos
        personales (Cancelación) u oponerse al uso (Oposición). Estos derechos
        se conocen como derechos ARCO.
      </p>
      <p>
        Para ejercer estos derechos, envíe un correo a nuestro oficial de
        privacidad a:{" "}
        <a href="mailto:atencionaclientes@connectiveit.com.mx">
          atencionaclientes@connectiveit.com.mx
        </a>
      </p>
      <h2>7. Mecanismos para revocar su consentimiento</h2>
      <p>
        Usted puede revocar el consentimiento otorgado para el tratamiento de
        sus datos personales. Envíe su solicitud a nuestro oficial de
        privacidad para conocer el procedimiento.
      </p>
      <h2>8. Opciones para limitar el uso o divulgación de sus datos</h2>
      <p>
        Puede solicitar su inscripción al “Listado de Exclusión Publicitaria”
        enviando un correo a nuestro oficial de privacidad.
      </p>
      <h2>9. Modificaciones al aviso de privacidad</h2>
      <p>
        Nos reservamos el derecho de efectuar modificaciones o actualizaciones
        al presente aviso de privacidad.
      </p>
      <h2>10. Tratamiento indebido de sus datos personales</h2>
      <p>
        Si considera que sus derechos han sido lesionados, puede presentar una
        denuncia ante el INAI en su página oficial:{" "}
        <a href="http://criteriosdeinterpretacion.inai.org.mx/Pages/default.aspx">
          http://criteriosdeinterpretacion.inai.org.mx/Pages/default.aspx
        </a>
        .
      </p>
      <h2>11. Consentimiento</h2>
      <p>
        Otorgo mi consentimiento para que mis datos personales sean tratados de
        conformidad con los términos del presente aviso de privacidad.
      </p>
    </div>
  );
};

export { Aviso };
