import React from 'react'
import './Resources.scss'
import { CarouselCom } from '../../Components/Carousel/Carousel'
import { CarouselSwiper } from '../../Components/CarouselSwiper/CarouselSwiper'
import { Title } from '../../Components/Title/Title'

import intro from '../../Assets/Images/Recursos/Imagen1.png'



import img_1 from '../../Assets/Images/Recursos/CaroucelRevista/Revista_1.png'
import img_2 from '../../Assets/Images/Recursos/CaroucelRevista/Revista_2.png'
import img_3 from '../../Assets/Images/Recursos/CaroucelRevista/Revista_3.png'
import img_4 from '../../Assets/Images/Recursos/CaroucelRevista/Revista_4.png'

import Rectangulo_carrusel from '../../Assets/Images/Recursos/Rectangulo_carrusel.png'
import imgReport_1 from '../../Assets/Images/Recursos/RevistaCaroucel.png'
import imgReport_2 from '../../Assets/Images/Recursos/Ebook.png'
import imgReport_3 from '../../Assets/Images/Recursos/Portal_cursos.png'

const Resources = () => {

  const ArrayImagesCambio = [
    { image: imgReport_1, title: '' },
    { image: imgReport_2, title: '' },
    { image: imgReport_3, title: '' }
  ]

  const slidesArray_1 = []
  if (ArrayImagesCambio.length > 0) {
    let contador = 1;
    for (let item of ArrayImagesCambio) {
      slidesArray_1.push(
        {
          key: contador,
          content: <CardImagesEdition image={item.image} title={item.title} />
        }
      )
      contador++
    }
  }


  const ArrayImages = [
    { image: img_1, title: 'Edición Diciembre' },
    { image: img_2, title: 'Edición Marzo' },
    { image: img_3, title: 'Edición Noviembre' },
    { image: img_4, title: 'Edición Enero' }
  ]

  const slidesArray = []
  if (ArrayImages.length > 0) {
    let contador = 1;
    for (let item of ArrayImages) {
      slidesArray.push(
        {
          key: contador,
          content: <CardImages image={item.image} title={item.title} />
        }
      )
      contador++
    }
  }
  return (
    <div className='Resources'>
      <div className='row resources-intro'>
        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 resources-intro-info'>
          <div className='row'>
            <div className='col-12 mt-5'><Title>Un lugar creado para ti</Title></div>
            <div className='resources-intro__p'>
              <p className='resources-intro__p--text'>
                APRENDE SOBRE PRODUCTIVIDAD, MICROSOFT, GOOGLE, VENTAS Y SEGURIDAD.
              </p>
            </div>
            <div className='col-12 mt-5 resources-intro__p'>
              <p className='resources-intro__p--description'>
                Hemos creado un portal de cursos para que nuestros clientes puedan capacitarse desde cualquier lugar y en cualquier momento.
              </p>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 resources-intro__content-img'>
          <img src={intro} alt="" className='resources-intro--img' />
        </div>
      </div>


      <div className='row resources-caroucel'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5'>
          {/* <CarouselSwiper ArrayImages={[imgReport_1, imgReport_2, imgReport_3]} /> */}
          <CarouselCom slidesArray={slidesArray_1} width='85%' />
        </div>
      </div>

      <div className='row resources-magazine'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center'>
          <Title>Lee nuestra revista digital <span className='color-b1'>gratuita</span></Title>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5'>
          <CarouselCom slidesArray={slidesArray} />
        </div>
      </div>


    </div>
  )
}

export { Resources };


const CardImagesEdition = ({ image }) => {
  return (
    <div className="CardImages-edition">
      <div className='CardImages-edition-img-back'>
      <a
              href="https://cursos.iwsservices.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
        <div className='CardImages-edition-img'>
          <img src={image} alt='https://cursos.iwsservices.com/' />
        </div>
        </a>
      </div>

    </div>
  );
};

const CardImages = ({ image, title }) => {
  return (
    <div className="CardImages">
      <div className="CardImages--img">
        <img src={image} alt='' />
      </div>
      <div className="CardResours__p">
        <p className="CardResours__p--text">{title}</p>
      </div>
    </div>
  );
};

