import React from "react";
import "./PowerApps.scss";
import { Title } from "../../Components/Title/Title";
import { Button } from "../../Components/Button/Button"; 
import img_intro from "../../Assets/Images/PoweApps/icon_intro.png";
import img_introM from "../../Assets/Images/PoweApps/Responsivo/Imagen1.png";
import fondo from "../../Assets/Images/PoweApps/fondo.png";
import imagen3 from "../../Assets/Images/PoweApps/imagen3.png";
import conectividad from "../../Assets/Images/PoweApps/Icono conectividad.png";
import codigo from "../../Assets/Images/PoweApps/codigo.png";
import plantilla from "../../Assets/Images/PoweApps/Icono plantilla.png";
import caso1 from "../../Assets/Images/PoweApps/Responsivo/datos.png";
import fondo_ofrecemos from "../../Assets/Images/PoweApps/Fondo_soluciones.png"
import caso2 from "../../Assets/Images/PoweApps/Responsivo/creacion.png";
import caso3 from "../../Assets/Images/PoweApps/Responsivo/plantilla.png";
import { Card } from "../../Components/Card/Card";
import { CarouselSwiper } from "../../Components/CarouselSwiper/CarouselSwiper";
import { RouterLink } from "../../Components/RouterLink/RouterLink";

const PowerApps = () => {
  return (
    <div className="PowerApps">
      <div className="row powerapps-intro">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 powerapps-intro-info">
          <div className="row">
            <div className="col-12 mt-5 d-movile powerapps-intro--title">
              <Title>
                <p>
                  <span className="color-subitlte-powerapps">
                    {" "}
                    <Title>Aplicaciones móviles</Title>
                  </span>
                </p>
              </Title>
            </div>
            <div className="col-12 mt-5 d-pc">
              <Title>
                Aplicaciones
                <br />
                móviles
              </Title>
            </div>
            <div className="col-12 mt-5 powerapps-intro__p">
              <p className="home-intro__p--description">
                Microsoft Power Apps proporciona un entorno de desarrollo de
                aplicaciones de bajo código para crear aplicaciones
                personalizadas y ricas en funciones.
              </p>
            </div>
            <div className="col-12 mt-5 powerapps-intro__button">
              <Button classButton="button__prymary">CONÓCENOS</Button>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 powerapps-intro__content-img">
          <img alt="" src={img_intro} className="powerapps-intro--img" />
          <img src={img_introM} alt="" className="about-intro--img--mobile" />
        </div>
        <img className="fondo_intro" src={fondo} />
      </div>

      <div className="row powerapps-caracteristicas">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 powerapps-intro__content-img">
          <img alt="" src={imagen3} className="powerapps-caracteristica-img" />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 powerapps-caracteristicas-info">
          <div className="row">
            <div className="col-12 mt-5 d-movile powerapps-caracteristicas--title">
              <Title>
                <p>
                  <span className="color-subitlte-powerapps">
                    {" "}
                    <Title>
                      Características
                      <br />
                      <span class="color-subitlte-powerapps">
                        del servicio:
                      </span>
                    </Title>
                  </span>
                </p>
              </Title>
            </div>
            <div className="col-12 mt-5 d-pc">
              <Title>
                Características
                <br />
                <span class="color-subitlte-powerapps">del servicio:</span>
              </Title>
            </div>
            <div className="col-12 mt-5 powerapps-caracteristicas">
              <p className="powerapps--description">
                Crea apps sin necesidad de tener conocimientos profundos de
                programación; arrastra y suelta elementos para diseñar la
                interfaz de usuario y definir la lógica de la aplicación.
              </p>
              <span className="Text_color--secundary">
                Además, Power Automate y Power Apps facilitan los flujos de
                trabajo automatizando tareas repetitivas.
              </span>
            </div>
            <div></div>
            <div className="col-12 mt-10 powerapps-intro__button">
              <Button classButton="button__prymary">
                INTEGRA ESTA SOLUCIÓN
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="automatizando_tareas_mobile">
              <span className="Text_color_mobile">
                Además, Power Automate y Power Apps facilitan los flujos de
                trabajo automatizando tareas repetitivas.
              </span>

              <div className="col-12 mt-10 powerapps-intro__button_mobile">
                <Button classButton="button__prymary">
                  INTEGRA ESTA SOLUCIÓN
                </Button>
              </div>
            </div>

      <div className="cards-mision-vision top_10 ">
        <div className="secction-cards">
          <Card
            title="Conectividad con datos"
            classColorTitle="vision"
            image={conectividad}
          >
            Facilita la conexión con bases de datos en la nube (como Microsoft
            365, SharePoint, Dynamics 365), servicios web, sistemas locales y
            otros servicios en la nube.
          </Card>
          <Card
            title="Creación sin código o bajo código"
            classColorTitle="vision"
            image={codigo}
          >
            Creación de aplicaciones utilizando una interfaz gráfica intuitiva
            que permite a los usuarios arrastrar y soltar elementos para diseñar
            la interfaz de usuario y definir la lógica de la aplicación
          </Card>
          <Card
            title="Plantillas y componentes reutilizables"
            classColorTitle="vision"
            image={plantilla}
          >
            Ofrece plantillas que pueden personalizarse y reutilizar componentes
            personalizados para acelerar el desarrollo en las aplicaciones.
          </Card>
        </div>
        {/* <div className="fondo-mision-vision">
          <img className="img-fondo-vision" src={fondoMision}/>
        </div> */}
      </div>

      <div className='row resources-caroucel-mobile'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-5'>
          <CarouselSwiper ArrayImages={[
              caso1, caso2, caso3]} />
        </div>
      </div>


      <div className="row solutions_offer__content">
        <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8 solutions_offer__info">
          <div className="solutions_offer">
            <div className="solutions_offer__title-text">
              <span className="solutions_offer--title">¿Qué ofrecemos?</span>
            </div>
            <div className="solutions_offer-description">
              <span className="solutions_offer--text">
                Delegar todos los servicios administrativos para una mejor
                productividad en base a la atención al cliente.
              </span>
            </div>
            <div className="solutions_offer-button">
              <RouterLink to="/">
                <Button>IMPULSA TU NEGOCIO</Button>
              </RouterLink>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 cont-btns">
          <Button classButton="btn-solutions_offer line-h">
            <p>
              <div className="solutions_offer__btn-number btn-hours-color">
                <span>24 hrs</span>
              </div>
              <div className="solutions_offer__btn-text btn-hours-color">
                <span>de servicio</span>
              </div>
            </p>
          </Button>

          <Button classButton="btn-solutions_offer--ticket line-h">
            <p>
              <div className="solutions_offer__btn-number btn-ticket-color">
                <span>10,000</span>
              </div>
              <div className="solutions_offer__btn-text btn-ticket-color">
                <span>Tickets resueltos</span>
              </div>
            </p>
          </Button>

        </div>
        <div className="img_que_ofrecemos">
          <img src={fondo_ofrecemos} className="que_ofrecemos"/>
          </div>
      </div>
    </div>
  );
};

export { PowerApps };
