import React, { useState } from "react";
import "./Chatbot.scss";

const Chatbot = () => {
  const [messages, setMessages] = useState([
    { text: "¡Hola! Soy tu asistente virtual. ¿Cómo te llamas?", sender: "bot" }
  ]);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [userName, setUserName] = useState("");  // Estado para el nombre del usuario
  const [userEmail, setUserEmail] = useState(""); // Estado para el correo del usuario
  const [step, setStep] = useState("askName"); // Control del flujo de la conversación

  const handleSendMessage = (message) => {
    if (!message.trim()) return;

    setMessages([...messages, { text: message, sender: "user" }]);
    setInput("");

    setIsTyping(true);
    setTimeout(() => {
      setIsTyping(false);
      handleBotResponse(message);
    }, 1500);
  };

  const handleBotResponse = (userMessage) => {
    if (step === "askName") {
      setUserName(userMessage);
      setMessages((prev) => [
        ...prev,
        { text: `¡Encantado, ${userMessage}! Ahora dime tu correo electrónico.`, sender: "bot" }
      ]);
      setStep("askEmail");
    } else if (step === "askEmail") {
      if (userMessage.includes("@")) {
        setUserEmail(userMessage);
        setMessages((prev) => [
          ...prev,
          { text: `Gracias, ${userName}. ¿En qué te puedo ayudar?`, sender: "bot", options: true }
        ]);
        setStep("mainMenu");
      } else {
        setMessages((prev) => [
          ...prev,
          { text: "Ese correo no parece válido. Por favor ingrésalo nuevamente.", sender: "bot" }
        ]);
      }
    } else if (step === "mainMenu") {
      handleMainMenu(userMessage.toLowerCase());
    } else {
      setMessages((prev) => [...prev, { text: "No entendí tu mensaje, intenta de nuevo.", sender: "bot" }]);
    }
  };

  const handleMainMenu = (message) => {
    let responseText = "";
    switch (message) {
      case "hablar con un asesor":
        responseText = "Voy a conectarte con un asesor. Por favor espera...";
        break;
      case "dudas y aclaraciones":
        responseText = "¿Qué duda tienes? Estoy aquí para ayudarte.";
        break;
      case "preguntas ia":
        responseText = "Puedes preguntarme sobre inteligencia artificial. ¿Qué te gustaría saber?";
        break;
      default:
        responseText = "No entendí tu mensaje, intenta de nuevo.";
    }
    setMessages((prev) => [...prev, { text: responseText, sender: "bot" }]);
  };

  return (
    <div className="chatbot-container">
      <div className="chatbot-header">CHAT WITH IWO</div>
      <div className="chatbot-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.sender}`}>
            {msg.text}
            {msg.options && (
              <div className="chatbot-options">
                <button className="chatbot-option" onClick={() => handleSendMessage("Hablar con un asesor")}>Hablar con un asesor</button>
                <button className="chatbot-option" onClick={() => handleSendMessage("Dudas y aclaraciones")}>Dudas y aclaraciones</button>
                <button className="chatbot-option" onClick={() => handleSendMessage("Preguntas IA")}>Preguntas IA</button>
              </div>
            )}
          </div>
        ))}
        {isTyping && (
          <div className="typing-indicator">
            <span></span><span></span><span></span>
          </div>
        )}
      </div>
      <div className="chatbot-input">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Escribe un mensaje..."
        />
        <button onClick={() => handleSendMessage(input)}>Enviar</button>
      </div>
    </div>
  );
};

export default Chatbot;
