import React, { useState } from "react";
import "./Preguntas.scss"; // Asegúrate de tener el archivo SCSS incluido

const preguntasData = [
  {
    categoria: "Portal Web",
    preguntas: [
      {
        pregunta: "¿Qué tipos de portales web desarrollan en ConnectiveIT?",
        respuesta: "Desarrollamos portales corporativos, e-commerce y sistemas personalizados.",
      },
      {
        pregunta: "¿Incluyen servicios de diseño UX/UI en los portales web?",
        respuesta: "Sí, ofrecemos diseño centrado en la experiencia del usuario.",
      },
      {
        pregunta: "¿Cuánto tiempo toma desarrollar un portal web personalizado?",
        respuesta: "Depende del proyecto, pero suele tomar entre 4 y 12 semanas.",
      },
    ],
  },
  {
    categoria: "Dynamics",
    preguntas: [
      {
        pregunta: "¿Qué soluciones ofrece ConnectiveIT con Microsoft Dynamics?",
        respuesta: "CRM, ERP y automatización de procesos empresariales.",
      },
      {
        pregunta: "¿Pueden personalizar Dynamics para necesidades específicas de mi negocio?",
        respuesta: "Sí, adaptamos Dynamics a tus requisitos específicos.",
      },
      {
        pregunta: "¿Ofrecen soporte técnico para Dynamics una vez implementado?",
        respuesta: "Sí, soporte técnico y funcional.",
      },
    ],
  },
  {
    categoria: "Power Apps",
    preguntas: [
      {
        pregunta: "¿Qué tipo de aplicaciones pueden desarrollarse con Power Apps?",
        respuesta: "Aplicaciones empresariales, de automatización y gestión de datos.",
      },
      {
        pregunta: "¿Es necesario tener conocimientos técnicos para usar Power Apps desarrolladas por ConnectiveIT?",
        respuesta: "No, son intuitivas y fáciles de usar.",
      },
    ],
  },
  {
    categoria: "Soluciones IA",
    preguntas: [
      {
        pregunta: "¿Qué tipos de soluciones basadas en inteligencia artificial ofrecen?",
        respuesta: "Chatbots, análisis predictivo y automatización inteligente.",
      },
      {
        pregunta: "¿Qué beneficios puede aportar la IA a mi negocio?",
        respuesta: "Mejora en eficiencia, reducción de costos y mejor toma de decisiones.",
      },
    ],
  },
];

const Preguntas = () => {
  const [preguntaAbierta, setPreguntaAbierta] = useState<string | null>(null);

  const togglePregunta = (pregunta: string) => {
    setPreguntaAbierta(preguntaAbierta === pregunta ? null : pregunta);
  };

  return (
    <div className="preguntas-frecuentes">
      <h1>Preguntas Frecuentes</h1>
      {preguntasData.map((categoria, index) => (
        <div key={index}>
          <h2>{categoria.categoria}</h2>
          {categoria.preguntas.map((item, idx) => (
            <div key={idx}>
              <div
                className={`pregunta ${
                  preguntaAbierta === item.pregunta ? "abierta" : ""
                }`}
                onClick={() => togglePregunta(item.pregunta)}
              >
                {item.pregunta}
              </div>
              {preguntaAbierta === item.pregunta && (
                <div className="respuesta">{item.respuesta}</div>
              )}
            </div>
          ))}
          <hr />
        </div>
      ))}
    </div>
  );
};

export { Preguntas };
